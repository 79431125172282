/* var myHeaders = new Headers();
myHeaders.append("Authorization", "Basic YmFoYW1hc3BhcmFkaXNlcmV0YWlsOjFKUkw1SDNXSlhPRE1U");
myHeaders.append("Content-Type", "application/json");

var captureUrl = 'https://m2m.kapturecrm.com/';

export const fetchTrips = async (rawData) => {
    try {
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: rawData,
            redirect: 'follow'
        };
      const response = await fetch(`${captureUrl}cruise-sailing-pricing-details`, requestOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    } catch (error) {
      throw new Error('Failed to fetch data');
    }
}; */

const ENV_MODE = process.env.REACT_APP_ENV;

var myHeaders = new Headers();
myHeaders.append(
  "Authorization",
  "Basic "+process.env.REACT_APP_CAPTURE_TOCKEN,
);
myHeaders.append("Content-Type", "application/json");
//myHeaders.append("mode", "cors/no-cors");
//myHeaders.append("Access-Control-Allow-Origin", "*");

//var M2MApiUrl = "https://appapi.m2mferries.com/";
//var M2MApiUrl = "https://m2mapi.mayradigital.com/";

var captureUrl = process.env.REACT_APP_VAR_CAPTURE_URL;
var M2MApiUrl = process.env.REACT_APP_VAR_M2MAPI_URL;
class TripDataService {


  fetchWithRetry = async (url, options, retries = 3, delay = 1000) => {
    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(url, options);
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const result = await response.json(); // Parse JSON response
        //console.log("Response:", result);
        return result; // Return the parsed response on success
      } catch (error) {
        //console.error(`Attempt ${i + 1} failed:`, error);
  
        if (i === retries - 1) {
          // Throw error after all retries are exhausted
          throw error;
        }
  
        // Optional: Add delay before retrying
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  fetchTrips = async (rawData) => {
    /* var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
      async: "async",
      timeout: 60000,
      mode: 'cors',
      dataType  : "json",
    };
    try {
      const response = await fetch(
        `${captureUrl}cruise-sailing-pricing-details`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
        // throw setIsBrowserAlerts("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    } */
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: rawData,
        redirect: "follow",
        async: "async",
        timeout: 60000,
        mode: 'cors',
        dataType  : "json",
      };
      
      // Using the function
      return this.fetchWithRetry(
        `${captureUrl}cruise-sailing-pricing-details`,
        requestOptions
      )
  };

  fetchMultipleTripVehicles = async (rawData) => {

    /* const myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic YmFoYW1hc3BhcmFkaXNlcmV0YWlsOjFKUkw1SDNXSlhPRE1U");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "JSESSIONID=B4DE3B0041A920E97DFB5C850EC65E4D; JSESSIONRID=3SDmlhjtZ1s2DmlhjtZ; _KAPTURECRM_SESSION=");


    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
      async: "async",
      timeout: 60000,
      mode: 'cors',
      dataType  : "json",
      credentials: 'include',
    }; */

    /* var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
      async: "async",
      timeout: 60000,
      mode: 'cors',
      dataType  : "json",
    };

    const fetchWithRetry = async (url, options, retries = 3) => {
      for (let i = 0; i < retries; i++) {
        try {
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          console.log("response : ", response.json());
          //return response.text(); // Return response on success
          return response.json();
        } catch (error) {
          console.error(`Attempt ${i + 1} failed:`, error);
          if (i === retries - 1) {
            throw error; // Throw error after all retries fail
          }
        }
      }
    };
    
    fetchWithRetry(
      "https://m2m.kapturecrm.com/get-cruise-supplement-list-for-multiple-sailing",
      requestOptions
    )
      .then((result) => console.log(result))
      .catch((error) => console.error("Final fetch error:", error)); */
    
    /* var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
      async: "async",
      timeout: 60000,
      mode: 'cors',
      dataType  : "json",
    };
    try {
      const response = await fetch(
        `${captureUrl}get-cruise-supplement-list-for-multiple-sailing`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    } */
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: rawData,
        redirect: "follow",
        async: "async",
        timeout: 60000,
        mode: 'cors',
        dataType  : "json",
      };
      
      // Using the function
      return this.fetchWithRetry(
        `${captureUrl}get-cruise-supplement-list-for-multiple-sailing`,
        requestOptions
      )
        /* .then((result) => console.log("Final result:", result))
        .catch((error) => console.error("Final fetch error:", error)); */
  };
  
  fetchCruiseCategoryAvailability = async (rawData) => {
    //var rawData = {sailing_date: "01/27/2024", sailing_time: "07:00:00", sailing_type: "custom", cruise_id: 54}
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
      async: "async",
      timeout: 60000,
      mode: 'cors',
      dataType  : "json",
    };
    return this.fetchWithRetry(
      `${captureUrl}get-cruise-category-availability`,
      requestOptions
    )
    /* try {
      const response = await fetch(
        `${captureUrl}get-cruise-category-availability`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    } */
  };

  fetchCruiseRoomAvailability = async (rawData) => {
    //var rawData = {sailing_date: "01/27/2024", sailing_time: "07:00:00", sailing_type: "custom", cruise_id: 54}
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    /* try {
      const response = await fetch(
        `${captureUrl}search-cruise-room-availability-block`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    } */
      return this.fetchWithRetry(
        `${captureUrl}search-cruise-room-availability-block`,
        requestOptions
      )
  };

  
  fetchCruiseBookingGuestPreview = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    /* try {
      const response = await fetch(
        `${captureUrl}get-cruise-booking-guest-booking-preview`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    } */
      return this.fetchWithRetry(
        `${captureUrl}get-cruise-booking-guest-booking-preview`,
        requestOptions
      )
  };


  fetchCruiseBookingConfirm = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    /* try {
      const response = await fetch(
        `${captureUrl}confirm-cruise-booking-guest-booking`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    } */
      return this.fetchWithRetry(
        `${captureUrl}confirm-cruise-booking-guest-booking`,
        requestOptions
      )
  };
  
  fetchCruiseCustomBookingPreview = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    /* try {
      const response = await fetch(
        `${captureUrl}cruise-custom-booking-priview`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    } */
      return this.fetchWithRetry(
        `${captureUrl}cruise-custom-booking-priview`,
        requestOptions
      )
  };
  
  fetchCruiseCustomBookingConfirm = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    /* try {
      const response = await fetch(
        `${captureUrl}cruise-custom-manual-booking-confirmation`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    } */
      return this.fetchWithRetry(
        `${captureUrl}cruise-custom-manual-booking-confirmation`,
        requestOptions
      )
  };


  /* fetchCruiseCategoryAvailability = async (rawData) => {
    //var rawData = {sailing_date: "01/27/2024", sailing_time: "07:00:00", sailing_type: "custom", cruise_id: 54}
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}get-cruise-category-availability`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  }; */

  fetchVehicles = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
      async: "async",
      timeout: 60000,
      mode: 'cors',
      dataType  : "json",
    };
    /* try {
      const response = await fetch(
        `${captureUrl}get-cruise-supplement-list-for-sailing`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    } */
      return this.fetchWithRetry(
        `${captureUrl}get-cruise-supplement-list-for-sailing`,
        requestOptions
      )
  };

  fetchTripsInfo = async (data) => {
    try {
      const response = await fetch(`${M2MApiUrl}api/trip-data`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to post data");
    }
  };

  

  fetchCustomerBooking = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    /* try {
      const response = await fetch(
        `${captureUrl}all-customer-booking`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    } */
      return this.fetchWithRetry(
        `${captureUrl}all-customer-booking`,
        requestOptions
      )
  };
  
  fetchCustomerBookingDetails = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    /* try {
      const response = await fetch(
        `${captureUrl}get-ferry-booking-check-in-details`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    } */
      return this.fetchWithRetry(
        `${captureUrl}get-ferry-booking-check-in-details`,
        requestOptions
      )
  };
}

export default new TripDataService();
