import React, { useEffect, useState } from "react";
import "./Signup.css";
import FotterAll from "../../Components/Footer/FotterAll";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import Select from 'react-select';
import UserDataService from "../../services/userData";
import StaticDataService from "../../services/staticData";
import Meta from '../../Components/Meta';

Modal.setAppElement("#root");

const Signup = () => {
   const [seoMeta, setSeoMeta] = useState(null);

    //FOR META TAGS
    useEffect(() => {
        StaticDataService.SeoMetaServices('SignUp')
        .then((response) => {
        
        if (response.status !== "error") {
            
            setSeoMeta(response.seoMeta[0]);
        
        } else {
            /* setError(response.message); */
        }
        })
        .catch((err) => {
        //setError(err.message);
        });
    }, []);



  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    countryCode: "+91",
  });

  const [countryCodes, setCountryCodes] = useState([]);
  const [formSectionVisible, setFormSectionVisible] = useState(true);
  const [newSectionVisible, setNewSectionVisible] = useState(false);
  const [resendTimer, setResendTimer] = useState(30); 
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [showResendText, setShowResendText] = useState(true);


  useEffect(() => {
    // Start the timer when isTimerRunning is true
    let timer;
    if (isTimerRunning) {
      timer = setInterval(() => {
        setResendTimer(prevTimer => prevTimer - 1);
      }, 1000);
    }

    // Clear the timer when it reaches 0
    if (resendTimer === 0) {
      clearInterval(timer);
      setIsTimerRunning(false);
      setShowResendText(true);
      setResendTimer(30); 
    }

    // Clean up function
    return () => clearInterval(timer);
  }, [isTimerRunning, resendTimer]);


  useEffect(() => {
    // Fetch the JSON file
    fetch('assets/CountryCodes.json')
      .then(response => response.json())
      .then(data => {
        setCountryCodes(data);
      })
      .catch(error => {
        console.error('Error fetching country codes:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    //REMOVE SPACES
    const nospaceValue = type === "text" ? value.replace(/\s+/g, '') : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : nospaceValue,
    }));
  };
  
  const onChange = (e) => {
    if (e) {
      setFormData(prevData => ({
        ...prevData,
        countryCode: e.value 
      }));
    } else {}
  };

  // otp section
  const [enteredOTP, setEnteredOTP] = useState(["", "", "", "", "", ""]);
  
  const handleOTPChange = (index, value) => {
    // Allow only numeric input
    const numericValue = value.replace(/\D/g, "");

    const newOTP = [...enteredOTP];
    newOTP[index] = numericValue;

    // Move focus to the next input box if a digit is entered
    if (numericValue.length === 1 && index < 5) {
      const nextIndex = index + 1;
      const nextInput = document.getElementById(`otpInputBox${nextIndex}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (numericValue.length === 0 && index > 0) {
      // Move focus to the previous input box if the current box is empty
      const prevIndex = index - 1;
      const prevInput = document.getElementById(`otpInputBox${prevIndex}`);
      if (prevInput) {
        prevInput.focus();
      }
    }

    setEnteredOTP(newOTP);
  };

  const handleSubmitToSections = () => {
    // const { first_name, last_name, email, mobile, countryCode } = formData;
  
    // if (!first_name || !last_name || !email || !mobile || !countryCode) {
    //   setIsBrowserAlerts("Please enter all details");
    //   return;
    // }

    const { first_name, last_name, email, mobile, countryCode } = formData;
      let errorMessage = "";

      if (!first_name || /[^A-Za-z]/.test(first_name)) {
        errorMessage = "Invalid first name. Use letters only.";
      }
      else if (!last_name || /[^A-Za-z]/.test(last_name)) {
        errorMessage = "Invalid last name. Use letters only.";
      }
      else if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        errorMessage = "Please enter a valid email address.";
      }
      else if (!mobile || mobile.length !== 10 || /\D/.test(mobile)) {
        errorMessage = "Mobile number must be exactly 10 digits.";
      }

      if (errorMessage) {
        setIsBrowserAlerts(errorMessage); 
        return;
      }
      
    var phoneNumber = formData.mobile;
    if (phoneNumber.length === 10) {
      var rawData = JSON.stringify({ mobile: phoneNumber, countryCode: formData.countryCode, step: "register" });
      UserDataService.requestOtp(rawData)
        .then((response) => {
          if (response.success) {
            setFormSectionVisible(false);
            setNewSectionVisible(true);
             // Start the timer when OTP is sent
             setIsTimerRunning(true);
             setShowResendText(false);
          } else {
            setIsBrowserAlerts(response.message);
            setEnteredOTP(["", "", "", "", "", ""]);
          }
        })
        .catch((err) => {});
      //
    } else {
      setIsBrowserAlerts("Please enter a valid mobile number");
    }
  };
  // onClick={SubmitOtpSignup}
  const SubmitOtpSignup = () => {
    const stringOtp = enteredOTP.map((value) => value).join("");
    //return false;
    const rawData = JSON.stringify({
      name: formData.first_name,
      last_name: formData.last_name,
      mobile: formData.mobile,
      email: formData.email,
      role: 'user',
      countryCode: formData.countryCode,
      otp:stringOtp
    });
    // console.log("signup-page rawData  :",rawData)

    UserDataService.userRegister(rawData)
    .then((response) => {
      if (response.success) {
        const userData = response.user_profile;
        // console.log("signup-page code userRegister service :",userData)
        localStorage.setItem("userData", JSON.stringify(userData));
        // setUserPData(userData);
        window.location = "/personal-information";
      } else {
        setIsBrowserAlerts(response.message);
        setEnteredOTP(["", "", "", "", "", ""]);
        return false;
      }
    })
    .catch((err) => {});
  };
  
  const goToBack = () => {
    setFormSectionVisible(true);
    setNewSectionVisible(false);
  };

  // onModel will be open(Popup)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isModalOpen) {
        // Close the modal when scrolling
        closeModal();
      }
    };

    if (isModalOpen) {
      // Disable body scrolling
      document.body.style.overflow = "hidden";
      // Add scroll event listener to close the modal
      window.addEventListener("scroll", handleScroll);
    } else {
      // Enable body scrolling
      document.body.style.overflow = "auto";
      // Remove scroll event listener
      window.removeEventListener("scroll", handleScroll);
    }

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isModalOpen]);

  const [TemsData,setTermsData] = useState()
  useEffect(()=>{
    StaticDataService.StaticData()
    .then((response) => {
      setTermsData(response.data[3].description)
    })
    .catch((err) => {
      console.log(err,"error")
    });

  },[])


   // For Browser ALerts
    const [isBrowserAlerts, setIsBrowserAlerts] = useState('');
    const closeBrowserModal = () => {
      setIsBrowserAlerts('');
    };

    const handleResendOTP = () => {
      // Resend OTP to the same mobile number
      handleSubmitToSections();
      // Reset timer
      setResendTimer(30);
      // Start timer again
      setIsTimerRunning(true);
    };

  return (
    <div className="Signup_container">
      {seoMeta ? (
            <Meta
            title={seoMeta.title}
            description={seoMeta.description}
            keywords={seoMeta.keywords}
            site_name = {seoMeta.site_name}
            canonicalUrl = {seoMeta.canonicalUrl}
            url={seoMeta.url}
            image = {seoMeta.image}
            />
      ):null}

      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar isHomepage={false} />
      </div>

      {formSectionVisible && (
        <div className="Singup_Content">
          <div className="Signup_Heading">
            <h1>Create Account</h1>
            <p>Welcome To M2M Ferries. Please Enter Your Details To Sign Up</p>
          </div>
          <div className="Signup_Form"> {/* onSubmit={handleSubmit}  */}
              <div className="Singup_label_guest_form">
                <label htmlFor="input1">First Name</label>
              </div>
              <input type="text" id="FirstName" className="Singup_input_guest" name="first_name" placeholder="Enter First-Name" defaultValue={formData.first_name} onChange={handleChange} required/>
              <br />
              <div className="Singup_label_guest_form">
                <label htmlFor="input2">Last Name</label>
              </div>
              <input type="text" id="input2" className="Singup_input_guest" name="last_name" placeholder="Enter Last-Name" value={formData.last_name} onChange={handleChange} required/>
              <br />
              <div className="Singup_label_guest_form">
                <label htmlFor="input3">Email Address</label>
              </div>
              <input type="text" id="input3" className="Singup_input_guest" name="email" placeholder="Enter Email" value={formData.email} onChange={handleChange} required/>
              <br />
              <div className="Singup_label_guest_form">
                <label htmlFor="input4">Mobile Number</label>
              </div>
              <div className="SignupInput_countryCode">
                <div className="Singup_custom_select_confirm">
                <Select
                  options={countryCodes}
                  // value={countryCodes.filter(function(option) {return option.value === formData.countryCode;})} 
                  value={countryCodes.find(option => option.value === formData.countryCode)} 
                  onChange={onChange}
                  // placeholder="+91"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "white" : "white",  
                      boxShadow: "none", 
                      '&:hover': { borderColor: "white" },
                      width:'110px',
                      border: "0.5px solid #767d8f80",
                      color: "#101010",
                      borderRadius: "16px", 
                      padding: "12px", 
                      "@media screen and (max-width: 767px)": {
                        width:'80px',
                        padding: "4px",
                        borderRadius: "10px",
                      },
                      "@media screen and (min-width: 768px) and (max-width: 1023px)": {
                        width:'87px',
                        padding: "5px",
                        borderRadius: "10px",
                      },
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      borderRadius: "16px",
                      boxShadow:'-1px 4px 99px #0000001a'
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "white",
                      color: "#101010",
                      opacity: "0.8",
                      fontSize: "1rem",
                      textAlign:'center',
                      fontFamily:'Poppins-Regular',
                      padding: "16px", 
                      borderBottom: state.isLastOption ? 'none' : '1px solid rgba(112, 112, 112, 0.2)'
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      color: "black",
                      opacity: "1",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "white" : "white",
                      border: 0,
                      color: "#101010;",
                      fontSize: "1.25rem",
                      "@media screen and (max-width: 767px)": {
                        fontSize: "0.875rem",
                      },
                    })
                  }}
                />
                </div>
                <input type="text" id="input4" className="Singup_input_guest_phoneNumber" name="mobile" placeholder="Enter Mobile Number" value={formData.mobile} onChange={handleChange} required/>
              </div>
              <br />
              <div className="Singup_AgreeTerms_checkbox">
                <p>
                  By Joining You Agree To The{" "}
                  <span onClick={openModal} style={{ cursor: "pointer", color: "#10b2d1",textDecoration:'underline' }}>
                    Terms and Conditions
                  </span>
                </p>
                <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Terms and Conditions Modal" className="custom-modal-TermsAndCondition">
                  <div className="TermsAndCoditon_M2M">
                    <div className="TermsAndCodition_Container">
                        <div className="DeskTop_TermsBackButton">
                          <div className="TermsAndCodition_Popup_Heading">
                              <h1>Terms And Conditions</h1>
                              <p onClick={closeModal}>x</p>
                          </div>
                        </div>
                        <div className='TermsAndcondition_SubText' dangerouslySetInnerHTML={{ __html: TemsData,}}/>
                        <div className="Mobile_TermsBackButton">
                          <div className="BtnTemrsAndCondition">
                            <button onClick={closeModal}>Back</button>
                          </div>
                        </div>
                    </div>
                  </div>
                </Modal>
              </div>

              <br />

              <div className="Singup_guest_book_button">
                <button onClick={handleSubmitToSections} className="Signup_Page_Button" type="submit">Generate OTP</button>
              </div>
              <div className="Singup_AgreeTerms_alerady">
                <p> Already Have An Account ? <a href="/login">Log In</a></p>
              </div>
          </div>
        </div>
      )}
      {/* sigup section otherwise otp section will be show */}
      {newSectionVisible && (
        <div className="OtpPAge_Content">
          <div className="OtpPAge_OtpDiv">
            <div className="OtpPAge_OtpDiv_heading">
              <h1>Verify Mobile Number</h1>
              <p>Sent To +91 XXXX-XXX-{formData.mobile.slice(-3)}</p>
            </div>
            <div className="OtpPage_Input">
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <input
                  key={index}
                  id={`otpInputBox${index}`}
                  type="text"
                  className="otp_input_box_signup"
                  maxLength="1"
                  value={enteredOTP[index]}
                  onChange={(e) => handleOTPChange(index, e.target.value)}
                />
              ))}
            </div>
            <div className="OtpPage_resend">
                   {showResendText ? (
                       <div className="OtpPage_resend" onClick={handleResendOTP}>
                             <p>Resend OTP</p>
                       </div>
                   ) : (
                       <div className="OtpPage_resend">
                          <p>{resendTimer} sec</p>
                       </div>
                   )}
            </div>
            <div>
              <button className="Signup_Page_Button" onClick={SubmitOtpSignup}>Sign-Up</button>
            </div>
            <div className="OnePAge_SignUp_permission_text">
              <p>Wrong Mobile Number?<span><Link style={{color:'#10b2d1'}} to={"/signup"} onClick={goToBack}>Edit</Link></span></p>
            </div>
          </div>
        </div>
      )}
      <div className="Signup_Footer">
        <FotterAll />
      </div>
      <Modal isOpen={isBrowserAlerts}  contentLabel="Example Modal" className="custom-modal-alert">
           <p>{isBrowserAlerts}</p>
           <div className="ModalButton"><button onClick={closeBrowserModal}>Ok</button></div>
        </Modal> 
    </div>
  );
};

export default Signup;
